<template>
  <v-card-actions :class="propsClass">
    <v-btn :width="btnSize" :outlined="btnOutline[0]" color="error" @click="$emit('onCancel')"><v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn>
    <v-btn :disabled="saveBtnDisable" :width="btnSize" :outlined="btnOutline[1]" color="success" @click="$emit('onSave')"
      ><v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn
    >
  </v-card-actions>
</template>
<script>
export default {
  props: {
    propsClass: {
      type: String,
      default: 'ma-0 pa-0'
    },
    btnSize: {
      type: Number,
      default: 240
    },
    btnOutline: {
      type: Array,
      default: () => [true, false]
    },
    saveBtnDisable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
